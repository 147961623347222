<template>
    <div class="page">
        <app-header :navIndex="3"></app-header>
        <banner :data="banner"></banner>
        <section class="top-box">
            <div class="top-border hidden-md-and-up white"></div>
            <div class="section-container">
                <div class="desc pa-5 pa-md-0 mt-md-12 mb-md-12" v-html="details.desc"></div>
                <!--<v-img :src="details.imgUrl"></v-img>-->
                <div class="pa-5 pa-md-0 mt-md-12 font-weight-bold text-title" v-html="details.title"></div>
            </div>
            <div class="d-flex flex-column mt-md-12 mb-md-12 pb-md-12">
                <div class="item-box mb-md-12" v-for="(item,index) in details.items"
                     :key="index">
                    <div class="item section-container d-flex flex-column flex-md-row align-center">
                        <div class="col-md-6 col-sm-12 pa-0" :class="index%2==0?'order-md-0':'order-md-1'">
                            <v-img :src="$utils.formatImgUrl(item.imgUrl)"></v-img>
                        </div>
                        <div class="col-md-6 col-sm-12 d-flex flex-column text-box mt-10 mt-md-0"
                             :class="index%2==0?'order-md-1':'order-md-0'">
                            <div :class="index%2==0?'text-left':'text-right'"><span
                                    class="item-title font-weight-bold"
                                    :class="index%2==0?'':'item-title1'">{{item.title}}</span>
                            </div>
                            <div class="d-flex flex-row mt-8 mt-md-12 ml-md-n10 mb-12 mb-md-0">
                                <div class="mr-md-6 font-weight-bold symbol hidden-sm-and-down">"</div>
                                <div class="desc">{{item.desc}}</div>
                            </div>
                            <div class="text-right font-weight-bold symbol hidden-sm-and-down">"</div>
                        </div>
                    </div>
                    <div class="bg hidden-sm-and-down" :class="index==3?'bg-right':'bg-left'" v-if="index==3||index==4">
                    </div>
                    <div class="other-bg hidden-sm-and-down" v-if="index==1"></div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import Banner from "../../components/base/banner";
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {
            AppHeader,
            Banner
        },
        data(){
            return {
                banner: {
                    imgUrl: 'employee/index/bg.jpg',
                    title: this.$i18n.t('others.employee.banner.title'),
                    enTitle: ''
                },
                details: {
                    desc: this.$i18n.t('others.employee.desc'),
                    imgUrl: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/index/img.jpg',
                    title: this.$i18n.t('others.employee.title'),
                    items: [{
                        imgUrl: 'employee/index/img_one.jpg',
                        title: 'Warehouse',
                        desc: 'I have loved it here for very long time.  ASC is a family of staff that are hard working and fun loving.'
                    }, {
                        imgUrl: 'employee/index/img_two.jpg',
                        title: 'PG',
                        desc: 'I enjoy working for ASC. It is challenging but exciting. Nothing makes me happier when I hear from my customer that they like the wines that I recommend them.'
                    }, {
                        imgUrl: 'employee/index/img_three.jpg',
                        title: 'Sales',
                        desc: 'The target is lofty and challenging but I can achieve it with hard and smart work. It makes me motivated to make a success here.'
                    }, {
                        imgUrl: 'employee/index/img_four.jpg',
                        title: 'Marketing',
                        desc: 'I like my job in ASC because we are enriching people’s life with one of the best things in the world – quality wines from around world.  Of course, it needs a lot of creativity and dedication to get my job well done. I feel proud to work in ASC.'
                    }, {
                        imgUrl: 'employee/index/img_five.jpg',
                        title: 'Finance',
                        desc: 'Working for ASC give me a very strong sense of fulfillment.  My job contributes to sustaining a healthy financial lifeline of the company. It is important for the company to grow well into the future.'
                    }, {
                        imgUrl: 'employee/index/img_six.jpg',
                        title: 'IT',
                        desc: 'Technology is the cornerstone of our business. We lay the technical foundation so that the company can build a magnificent palace of wine on it, where we promote wine culture and bring the pleasure of wine to consumers.'
                    }, {
                        imgUrl: 'employee/index/img_seven.jpg',
                        title: 'Wine education',
                        desc: 'We are advocates of a wonderful lifestyle.'
                    }]
                }
            }
        },
        created(){
            this.initData()
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "我们的员工"
            document.getElementById("bodyId").appendChild(div);
        },
        methods: {
            initData(){
                this.$api.web.getSocialOrEmployee({type: 2}).then(res => {
                    if (res.rows.length > 0) {
                        this.details.items = res.rows
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .text-title {
        font-size: 35px;
    }

    .item-box {
        position: relative;
        .item {
            position: relative;
            z-index: 1;
            .text-box {
                padding: 0 100px;
                .item-title {
                    font-size: 30px;
                    position: relative;
                    &:before {
                        content: "";
                        width: 80%;
                        height: 2px;
                        background: $primary-color;
                        position: absolute;
                        bottom: -15px;
                        left: 0;
                        right: auto;
                    }
                }
                .item-title1 {
                    &:before {
                        left: auto;
                        right: 0;
                    }
                }
                .symbol {
                    font-size: 50px;
                    color: #999999;
                    font-family: DINAlternate-Bold, DINAlternate;
                }
            }
        }
        .bg {
            @include tb();
            width: calc(25%);
            /*width: 300px;*/
            height: calc(100% + 96px);
            background-color: #F3F3F3;
        }
        .bg-right {
            right: 0;
        }
        .bg-left {
            left: 0;
        }
        .other-bg {
            position: absolute;
            top: 65%;
            transform: translateY(-65%);
            width: 100%;
            height: calc(45%);
            background-color: #F3F3F3;
        }
    }

    @media (max-width: $screen-md) {
        .text-title {
            font-size: 20px;
        }
        .item-box {
            .item {
                .text-box {
                    padding: 0 20px;
                    .item-title {
                        font-size: 16px;
                    }
                }
            }
        }

    }
</style>
